import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik';
import { number, date, object, string, array } from 'yup';
import DateTimePicker from 'react-datetime-picker';
import FormRatings from 'form-ratings';
import { Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';

import { configuration } from '../../../configuration';
import { ModalComponent as Modal } from '../Modal/Modal';
import AppContext from '../../../store/appContext';
import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';
import BStrapFormikInput from '../BStrapFormikInput';

interface IDepartment {
  id: number;
  name: string;
  address: string;
  lat: string;
  lng: string;
}

interface IDivision {
  id: number;
  name: string;
  address: string;
  lat: string;
  lng: string;
  departments: IDepartment[];
}

export interface IOffice {
  id: number;
  name: string;
  divisions: IDivision[];
}

interface IQuestion {
  id: number;
  name: string;
}

export interface IQuestionStep {
  id: number;
  name: string;
  questions: IQuestion[];
}

interface IRatingList {
  id: number;
  value: number;
}

interface IRating {
  date: string;
  department_id: number;
  desk?: string;
  source: string;
  final_rating: number;
  rating_description?: string;
  ratings_list: IRatingList[];
}

type TQuestionFormValue = IQuestion & { value: number | null };

interface IFormValues {
  selectOffice: string;
  selectDivision: string;
  selectDepartment: string;
  dateTime: Date;
  officeDeskNr: string;
  ratingDescription: string;
  questions: TQuestionFormValue[];
}

const style = { border: '3px solid #2dcfa7', maxWidth: '50rem' };

// Yup validation schema
const officeRatingSchema = object().shape({
  selectOffice: number().required('Vyplňte, prosím'),
  selectDivision: number().required('Vyplňte, prosím'),
  selectDepartment: number().required('Vyplňte, prosím'),
  dateTime: date().required('Vyplňte, prosím'),
  officeDeskNr: string(),
  ratingDescription: string(),
  questions: array().of(
    object().shape({
      id: number(),
      name: string(),
      value: number().typeError('Vyplňte, prosím'),
    })
  ),
});

const OfficeForm: FC = () => {
  const { error, setError, setIsLoading, setShow } = useContext(AppContext);

  const [valueDateTime, setDateTime] = useState(new Date());
  const [officesData, setOfficesData] = useState<IOffice[]>([]);
  const [selectedOffice, setSelectedOffice] = useState<string>('');
  const [selectedDivision, setSelectedDivision] = useState<string>('');
  const [selectedDepartment, setSelectedDepartment] = useState<string>('');
  const [initialValues, setInitialValues] = useState<IFormValues>({
    selectOffice: '',
    selectDivision: '',
    selectDepartment: '',
    dateTime: valueDateTime,
    officeDeskNr: '',
    ratingDescription: '',
    questions: [],
  });

  // get offices
  const getOffices = useCallback<() => void>(async () => {
    setIsLoading(true);
    setError(null);
    const url = `${configuration.API_URL}/office-management/offices?includeDivisions=true`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `${configuration.API_KEY}`,
          'Content-Type': 'application/json',
        },
      });
      setOfficesData(response.data);
      if (response.data.length === 1) {
        setSelectedOffice(response.data[0].id.toString());
      }
    } catch (error: any) {
      console.log('error', error);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get questions
  const getQuestions = useCallback<() => void>(async () => {
    setIsLoading(true);
    setError(null);
    const url = `${configuration.API_URL}/office-management/questions`;
    try {
      const response = await axios.get<IQuestionStep[]>(url, {
        headers: {
          Authorization: `${configuration.API_KEY}`,
          'Content-Type': 'application/json',
        },
      });

      const questions: IQuestion[] = response.data
        .map((step) => step.questions)
        .reduce((prev, current) => [...prev, ...current]);

      const initialQuestionValues = questions.map((el) => ({
        ...el,
        value: null,
      }));

      setInitialValues({
        ...initialValues,
        questions: initialQuestionValues,
      });
    } catch (error: any) {
      console.log('error', error);
      setError(() => error.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // send rating
  const sendRating = async (payload: any) => {
    setIsLoading(true);
    setError(null);
    const url = `${configuration.API_URL}/office-management/ratings`;
    try {
      await axios.post(url, payload, {
        headers: {
          Authorization: `${configuration.API_KEY}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (error: any) {
      console.log('error', error);
      setError(() => error.message);
    }
    setShow(() => true);
    setIsLoading(false);
  };

  useEffect(() => {
    getOffices();
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AutoSelectComponent = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      setFieldValue('selectOffice', selectedOffice);

      const targetOfficeObject = officesData.find((el) => {
        return el.id.toString() === selectedOffice;
      });

      if (targetOfficeObject?.divisions.length === 1) {
        setSelectedDivision(targetOfficeObject.divisions[0].id.toString());
        setFieldValue('selectDivision', selectedDepartment);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOffice]);

    useEffect(() => {
      setFieldValue('selectDivision', selectedDivision);

      const targetOfficeObject = officesData.find((el) => {
        return el.id.toString() === selectedOffice;
      });

      const targetDivisionObj = targetOfficeObject?.divisions.find((el) => {
        return el.id.toString() === selectedDivision;
      });

      if (targetDivisionObj?.departments.length === 1) {
        setSelectedDepartment(targetDivisionObj.departments[0].id.toString());
        setFieldValue('selectDepartment', selectedDepartment);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDivision]);

    return null;
  };

  const fOfficesDropDown = () => {
    const _fOfficesDropDown: any[] = [];

    _fOfficesDropDown.push(
      <option disabled key={100000} value={''}>
        Vyberte úřad
      </option>
    );

    officesData.forEach((el, index) => [
      _fOfficesDropDown.push(
        <option key={index} value={el.id}>
          {el.name}
        </option>
      ),
    ]);

    return _fOfficesDropDown;
  };

  const fDivisionsDropDown = () => {
    if (!officesData || !selectedOffice)
      return (
        <option disabled key={100000} value={''}>
          Vyberte odbor
        </option>
      );

    const targetOfficeObject = officesData.find((el) => {
      return el.id.toString() === selectedOffice;
    });

    const divisionsArr = targetOfficeObject?.divisions || [];
    const _divisionDropDown = [];

    _divisionDropDown.push(
      <option disabled key={100000} value={''}>
        Vyberte odbor
      </option>
    );

    divisionsArr.forEach((el, index: number) => {
      _divisionDropDown.push(
        <option key={index} value={el.id}>
          {el.name}
        </option>
      );
    });

    return _divisionDropDown;
  };

  const fDepartmentsDropDown = () => {
    if (!officesData || !selectedOffice || !selectedDivision)
      return (
        <option disabled key={100000} value={''}>
          Vyberte oddělení
        </option>
      );

    const _departmentsDropDown: any[] = [];

    const targetOfficeObject = officesData.find((el) => {
      return el.id.toString() === selectedOffice;
    });

    const divisionsArr = targetOfficeObject?.divisions || [];

    const targetDivisionObj = divisionsArr.find((el) => {
      return el.id.toString() === selectedDivision;
    });

    _departmentsDropDown.push(
      <option disabled key={100000} value={''}>
        Vyberte oddělení
      </option>
    );

    const departmentsArr = targetDivisionObj?.departments || [];

    departmentsArr.forEach((el, index: number) => {
      _departmentsDropDown.push(
        <option key={index} value={el.id}>
          {el.name}
        </option>
      );
    });

    return _departmentsDropDown;
  };

  const officesDropDown = fOfficesDropDown();
  const divisionsDropDown = fDivisionsDropDown();
  const departmentsDropDown = fDepartmentsDropDown();

  const thankYouMessage = (
    <div>
      Vážená paní, vážený pane,
      <br />
      <br />
      děkujeme Vám, že jste využili hodnocení úřadů prostřednictvím aplikace zmente.to.
      <br />
      <br />
      Děkujeme, že máte chuť měnit spolu s námi Prahu k lepšímu. Neváhejte ohodnotit i další úřady,
      se kterými máte zkušenost.
      <br />
      <br />
      Zdraví Vás
      <br />
      Tým zmente.to
      <br />
      <br />
    </div>
  );

  const modalTitle = !error ? 'Děkujeme vám za vaše hodnocení' : 'Chyba';
  const modalBodyText = !error ? thankYouMessage : error;

  return (
    <Container className="mb-5">
      <Modal title={modalTitle} body={modalBodyText} />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={officeRatingSchema}
        validateOnBlur={true}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          const payload: IRating = {
            date: values.dateTime.toISOString(),
            department_id: +values.selectDepartment,
            desk: values.officeDeskNr,
            source: 'WEB',
            final_rating:
              values.questions.reduce((sum, q) => sum + (q.value || 0), 0) /
                values.questions.length || 1,
            rating_description: values.ratingDescription,
            ratings_list: values.questions.map((q) => ({ id: q.id, value: q.value! })),
          };
          await sendRating(payload);
          actions.resetForm();
          if (officesData.length === 1) {
            setSelectedOffice(officesData[0].id.toString());
          } else {
            setSelectedOffice('');
          }
          actions.setSubmitting(false);
        }}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Row>
            <Col lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
              <Form>
                <AutoSelectComponent />
                <div className="mb-3">
                  <label className="form-label d-block" htmlFor="selectOffice">
                    Vyberte úřad
                  </label>
                  <Field
                    as="select"
                    name="selectOffice"
                    placeholder="Vyberte úřad"
                    className="form-control mb-3"
                    style={style}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setSelectedOffice(e.target.value);
                      setSelectedDepartment('');
                      setSelectedDivision('');
                      setFieldValue('selectOffice', e.target.value);
                      setFieldValue('selectDivision', '');
                      setFieldValue('selectDepartment', '');
                    }}
                    value={selectedOffice}
                  >
                    {officesDropDown}
                  </Field>
                  {errors.selectOffice && touched.selectOffice ? (
                    <StyledErrorMessage>{errors.selectOffice}</StyledErrorMessage>
                  ) : null}
                </div>

                <div className="mb-3">
                  <label className="form-label d-block" htmlFor="selectDivision">
                    Vyberte odbor
                  </label>
                  <Field
                    as="select"
                    name="selectDivision"
                    placeholder="Vyberte odbor"
                    className="form-control mb-3"
                    style={style}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setSelectedDivision(e.target.value);
                      setSelectedDepartment('');
                      setFieldValue('selectDivision', e.target.value);
                      setFieldValue('selectDepartment', '');
                    }}
                    value={selectedDivision}
                  >
                    {divisionsDropDown}
                  </Field>
                  {errors.selectDivision && touched.selectDivision ? (
                    <StyledErrorMessage>{errors.selectDivision}</StyledErrorMessage>
                  ) : null}
                </div>

                <div className="mb-3">
                  <label className="form-label d-block" htmlFor="selectDepartment">
                    Vyberte oddělení
                  </label>
                  <Field
                    as="select"
                    name="selectDepartment"
                    placeholder="Vyberte oddělení "
                    className="form-control mb-3"
                    style={style}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setSelectedDepartment(e.target.value);
                      setFieldValue('selectDepartment', e.target.value);
                    }}
                    value={selectedDepartment}
                  >
                    {departmentsDropDown}
                  </Field>
                  {errors.selectDepartment && touched.selectDepartment ? (
                    <StyledErrorMessage>{errors.selectDepartment}</StyledErrorMessage>
                  ) : null}
                </div>

                <div className="mb-3">
                  <BStrapFormikInput
                    id="officeDeskNr"
                    name="officeDeskNr"
                    label="Číslo přepážky (nepovinné)"
                    placeholder="Kde jsme Vás obsloužili?"
                    type="text"
                    style={style}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label d-block" htmlFor="datetime">
                    Zadejte datum a čas Vaší návštěvy
                  </label>
                  <DateTimePicker className="mb-3" value={valueDateTime} onChange={setDateTime} />
                  {errors.dateTime && touched.dateTime ? (
                    <StyledErrorMessage>{errors.dateTime}</StyledErrorMessage>
                  ) : null}
                </div>
                <FieldArray name="questions">
                  {() =>
                    values.questions.map((question, i) => {
                      return (
                        <div key={question.id} className="mb-3">
                          <label className="form-label">{question.name}</label>
                          <Field name={`questions.${i}.value`} as={FormRatings} />
                          {errors.questions?.length &&
                          errors.questions[i] &&
                          touched.questions?.length &&
                          touched.questions[i] ? (
                            // @ts-ignore
                            <StyledErrorMessage>{errors.questions[i].value}</StyledErrorMessage>
                          ) : null}
                        </div>
                      );
                    })
                  }
                </FieldArray>
                <Field
                  as="textarea"
                  rows={5}
                  name="ratingDescription"
                  placeholder="Napište Vaše hodnocení (nepovinné)"
                  className="form-control mb-3"
                  style={style}
                />
                <button
                  className="btn btn-outline-dark"
                  type="submit"
                  style={style}
                  disabled={isSubmitting}
                >
                  Odeslat
                </button>
              </Form>
            </Col>
          </Row>
        )}
      </Formik>
    </Container>
  );
};

export default OfficeForm;
