import { FC, Fragment, MouseEventHandler } from 'react';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

import StyledResetButton from '../StyledResetButton/StyledResetButton';
import StyledCloseBadge from '../StyledCloseBadge/StyledCloseBadge';

export interface IFiles {
  id: number;
  path: string;
  filename: string;
  order: number;
}

const StyledText = styled.div`
  margin-bottom: 0.1rem;
  background-color: #24a1;
  padding: 0.1rem 0.5rem;
  border-radius: 0.2rem;
`;

const FileField: FC<{
  files: IFiles[];
  survey_id?: string;
  onDelete?: (id: number) => void;
  onReset?: () => void;
  onClick?: MouseEventHandler<HTMLImageElement>;
  controls?: boolean;
  imageWidth?: number;
}> = ({ files, survey_id, onDelete, onReset, onClick, controls, imageWidth }) => {
  return (
    <Fragment>
      <StyledText>
        <div className="d-flex justify-content-between mb-1">
          {controls && <StyledResetButton onClick={() => onReset!()} />}
        </div>
        <div className="d-flex flex-wrap">
          {files?.map((file) => {
            return (
              <div key={file.id!} className="position-relative">
                <Image
                  thumbnail
                  src={file.path!}
                  alt={survey_id}
                  className="mx-1"
                  style={{ maxWidth: `${imageWidth ? imageWidth : 6}rem` }}
                  onClick={onClick}
                />
                {controls && (
                  <StyledCloseBadge
                    onClick={() => {
                      onDelete!(file.id);
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </StyledText>
    </Fragment>
  );
};

export default FileField;
