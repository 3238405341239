import AppContext from '../../../store/appContext';
import { FC, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface IModal {
  title?: string;
  body?: string | JSX.Element;
}

export const ModalComponent: FC<IModal> = (props): JSX.Element => {
  const { setShow, show } = useContext(AppContext);
  let navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    navigate('/');
  };

  return (
    <Modal show={show} onHide={handleClose} style={{ top: '20vh' }}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-success" aria-label="Close" onClick={handleClose}>
          Zavřít
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
