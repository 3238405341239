import { FC, memo, useContext, useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import AppContext from '../../../../store/appContext';
import { configuration } from '../../../../configuration';
import AutoComp from '../Autocomplete';

import styles from '../../SuggestionForm/SuggestionForm.module.scss';

const LoadingElement = () => {
  return <span>Nahrávám...</span>;
};

const usedGoogleLibraries: ['places' | 'drawing' | 'geometry' | 'localContext' | 'visualization'] =
  ['places'];

const hlmpLocation = {
  lat: 50.08712109608858,
  lng: 14.417847885347497,
};

const GMap: FC<{
  isAutocomplete?: boolean;
}> = ({ isAutocomplete }) => {
  const { LatLng, setLatLng } = useContext(AppContext);
  const [zoom] = useState(17); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(hlmpLocation);

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (!LatLng) {
      setLatLng(() => e.latLng?.toJSON());
    }
  };

  const resetPositionHandler = () => {
    setLatLng(() => null);
  };

  const setCenterHandler = (LatLng: google.maps.LatLngLiteral) => {
    setCenter({ ...center, lat: LatLng.lat, lng: LatLng.lng });
  };

  const handleMarkerLoad = (marker: google.maps.Marker) => {
    setCenterHandler(marker.getPosition()!.toJSON());
  };

  const getMarkerposition = (e: google.maps.MapMouseEvent) => {
    setLatLng(() => e.latLng?.toJSON());
    setCenterHandler(e.latLng!.toJSON());
  };

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
    border: '3px solid #2dcfa7',
  };
  const options = {
    center: center,
    zoom: zoom,
    id: 'google-map',
  };

  const ResetPosition = () => {
    return (
      <div
        style={{
          flexBasis: '75px',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <button
          className={`btn ${styles['reset-button']}`}
          style={{ border: '3px solid #2dcfa7', marginTop: '0.5rem' }}
          type="button"
          onClick={resetPositionHandler}
        >
          Smazat polohu
        </button>
      </div>
    );
  };

  useEffect(() => {
    LatLng && setCenterHandler(LatLng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LatLng]);

  const renderMap = () => {
    return (
      <LoadScript
        googleMapsApiKey={configuration.GOOGLE_API_KEY!}
        libraries={usedGoogleLibraries}
        language="cs"
        region="cz"
        loadingElement={<LoadingElement />}
      >
        <>
          <GoogleMap mapContainerStyle={mapContainerStyle} options={options} onClick={onClick}>
            {LatLng && (
              <Marker
                position={LatLng}
                onDragEnd={getMarkerposition}
                onLoad={handleMarkerLoad}
                draggable
              />
            )}
          </GoogleMap>
          <ResetPosition />
          <AutoComp />
        </>
      </LoadScript>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        height: isAutocomplete ? '500px' : '427px',
      }}
    >
      {renderMap()}
    </div>
  );
};

export default memo(GMap);
