import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Props } from '../../../App';

const GDPR: FC<Props> = (props): JSX.Element => {
  return (
    <section className="top">
      <Container fluid="md">
        <h3 className="mb-5 display-6 text-center">{props.header}</h3>
        <Row className="mb-5">
          <Col sm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <h4>1. Správce osobních údajů</h4>
            <p className="px-2">
              <strong>1.1.</strong> Hlavní město Praha, se sídlem Praha 1, Staré Město, Mariánské
              náměstí 2/2, IČO: 00064581, jako správce (dále jen „Správce“) zpracovává osobní údaje
              registrovaných uživatelů (dále jen „uživatel“ nebo „Vy“) webové a mobilní aplikace
              zmente.to (dále jen „aplikace“). Uživatelé mohou v otázkách zpracování svých osobních
              údajů kontaktovat Správce na e-mailové adrese posta@praha.eu. V souladu s Nařízením
              Evropského parlamentu a Rady č. 2016/679 ze dne 27.4.2016 o ochraně fyzických osob v
              souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení
              směrnice 95/46/ES Správce jmenoval též osobu pověřence pro ochranu osobních údajů,
              kterého můžete kontaktovat na poverenecgdpr@praha.eu.
            </p>
            <p className="px-2">
              <strong>1.2.</strong> Správce zpracovává osobní údaje uživatelů v souladu s obecně
              závaznými právními předpisy, zejména Nařízením Evropského parlamentu a Rady č.
              2016/679 ze dne 27. 4. 2016 o ochraně fyzických osob v souvislosti se zpracováním
              osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále jen
              „GDPR“).
            </p>
            <h4>2. Rozsah zpracovávaných osobních údajů</h4>
            <p className="px-2">
              <strong>2.1.</strong> Správce zpracovává identifikační a kontaktní údaje uživatele,
              údaje, které uživatel dobrovolně sdělí Správci a zadá při registraci do aplikace, a
              dále údaje a informace shromážděné Správcem při komunikaci s uživatelem a při
              využívání aplikace ze strany uživatele.
            </p>
            <p className="px-2">
              <strong>2.2.</strong> Správce zpracovává osobní údaje, které jsou přiměřené,
              relevantní a omezené na nezbytný rozsah ve vztahu k účelu, pro který jsou
              zpracovávány.
            </p>
            <h4>3. Účel zpracování osobních údajů</h4>
            <p className="px-2">
              <strong>3.1.</strong> Správce zpracovává osobní údaje uživatele pro následující účely:
              <p className="px-2">
                <strong>(a)</strong> registrace uživatele do aplikace a umožnění využívání aplikace
                uživatelem; tedy pro účely uzavření a plnění smlouvy (podle čl. 6 odst. 1 písm. b)
                GDPR) a pro účely veřejného zájmu spočívajícího v zajištění funkčnosti aplikace
                (podle čl. 6 odst. 1 písm. e) GDPR);
              </p>
              <p className="px-2">
                <strong>(b)</strong> komunikace s uživatelem a vyřizování a reagování na podněty
                uživatele učiněné prostřednictvím aplikace; tedy pro účely plnění smlouvy uzavřené s
                uživatelem (podle čl. 6 odst. 1 písm. b) GDPR) a pro účely veřejného zájmu
                spočívajícího v zajištění spokojenosti uživatelů při využívání aplikace (podle čl. 6
                odst. 1 písm. e) GDPR);
              </p>
              <p className="px-2">
                <strong>(c)</strong> kontrola využívání aplikace ze strany uživatele a ochrana
                aplikace a práv Správce, a to na základě oprávněných zájmů Správce (podle čl. 6
                odst. 1 písm. f) GDPR);
              </p>
              <p className="px-2">
                <strong>(d)</strong> informování uživatele o nových funkcích aplikace či službách
                zpřístupněných prostřednictvím aplikace, a pro účely veřejného zájmu spočívajícího v
                zajištění funkčnosti aplikace (podle čl. 6 odst. 1 písm. e) GDPR);
              </p>
              <p className="px-2">
                <strong>(e)</strong> pokud nám k tomu udělíte souhlas, můžeme Vaše osobní údaje
                zpracovávat též pro účely uvedené v takovém souhlasu (na základě čl. 6 odst. 1 písm.
                a) GDPR).
              </p>
            </p>
            <p className="px-2">
              <strong>3.2.</strong> Poskytnutí osobních údajů ze strany uživatele k výše uvedeným
              účelům je smluvním požadavkem. Uživatel nemá zákonem uloženou povinnost osobní údaje
              Správci poskytnout, bez jejich poskytnutí se ale uživatel nebude moci registrovat k
              aplikaci a využívat jejích funkcí a služeb.
            </p>

            <h4>4. Doba zpracování osobních údajů</h4>
            <p className="px-2">
              <strong>4.1.</strong> Správce bude zpracovávat osobní údaje uživatele pouze po dobu,
              která je nezbytná vzhledem k účelu jejich zpracování. Pokud jsou osobní údaje
              používány současně pro více různých účelů zpracování, bude je Správce zpracovávat,
              dokud neodpadne účel s delší dobou zpracování. K účelu s kratší dobou zpracování je
              však Správce přestane používat, jakmile tato doba uplyne.
            </p>
            <p className="px-2">
              <strong>4.2.</strong> Správce používá následující kritéria pro stanovení doby
              zpracování osobních údajů uživatelů:
              <p className="px-2">
                <strong>(a)</strong> Správce bude zpracovávat osobní údaje uživatele po dobu trvání
                jeho registrace k aplikaci;
              </p>
              <p className="px-2">
                <strong>(b)</strong> po skončení registrace uživatele k aplikaci mohou být jeho
                osobní údaje dále zpracovávány z důvodu existence oprávněných zájmů Správce na
                ochraně aplikace a její funkčnosti, nebo do skončení soudního, správního nebo jiného
                řízení, ve kterém jsou řešeny práva či povinnosti Správce ve vztahu k uživateli
                (podle toho, která skutečnost nastane později);
              </p>
              <p className="px-2">
                <strong>(c)</strong> k informování uživatele o nových funkcích aplikace či službách
                zpřístupněných prostřednictvím aplikace budou osobní údaje uživatele zpracovávány do
                doby, než vyjádří svůj nesouhlas s takovým zpracováním;
              </p>
              <p className="px-2">
                <strong>(d)</strong> osobní údaje zpracovávané na základě souhlasu uživatele bude
                Správce zpracovávat do doby, než uživatel udělený souhlas odvolá, nestanoví-li
                Správce kratší dobu zpracování.
              </p>
            </p>
            <p className="px-2">
              <strong>4.3.</strong> Nejpozději do konce kalendářního čtvrtletí následujícího po
              uplynutí stanovené doby zpracování budou příslušné osobní údaje, u kterých pominul
              účel jejich zpracování, zlikvidovány (skartací nebo jiným způsobem, který zajistí, že
              se osobní údaje nadále nezpracovávají.
            </p>
            <h4>5. Příjemci osobních údajů</h4>
            <p className="px-2">
              <strong>5.1.</strong> Správce může předat osobní údaje uživatele příslušným orgánům
              veřejné moci a dalším příjemcům, kterým je Správce povinen předávat osobní údaje podle
              obecně závazných právních předpisů.
            </p>
            <p className="px-2">
              <strong>5.2.</strong> Pro účely vyřízení Vašich podnětů učiněných prostřednictvím
              aplikace může Správce předat Vaše osobní údaje příjemcům, jejichž součinnost je
              nezbytná pro vyřešení Vašeho podnětu.
            </p>
            <p className="px-2">
              <strong>5.3.</strong> Správce je dále oprávněn předat osobní údaje příjemcům, se
              kterými uzavřel smlouvu o zpracování osobních údajů a kteří budou pro Správce
              zpracovávat osobní údaje jako jeho zpracovatelé. Na žádost uživatele mu Správce
              poskytne informaci o těch zpracovatelích, kteří se podílejí na zpracování jeho
              osobních údajů.
            </p>
            <p className="px-2">
              <strong>5.4.</strong> Ke zpracování osobních údajů bude docházet výlučně v rámci
              Evropského hospodářského prostoru (EHP). Pokud by mělo dojít k předání osobních údajů
              do třetí země mimo EHP, přijme Správce před uskutečněním předání vhodné záruky podle
              čl. 44 a násl. GDPR.
            </p>
            <h4>6. Práva uživatele</h4>
            <p className="px-2">
              <strong>6.1.</strong> V souvislosti se zpracováním osobních údajů má uživatel níže
              uvedená práva. Na výkon těchto práv se nicméně vztahují určité výjimky, a proto je
              není možné uplatnit ve všech situacích. V případě, že uplatníte Vaše práva a Vaše
              žádost bude shledána oprávněnou, Správce přijme požadovaná opatření bez zbytečného
              odkladu, nejpozději do jednoho měsíce (v důvodných případech lze tuto lhůtu prodloužit
              až o další dva měsíce).
              <p className="pt-2">
                Právo na přístup k osobním údajům:
                <br />
                Máte právo na přístup k Vašim osobním údajům zpracovávaným Správcem.
              </p>
              <p>
                Právo na opravu:
                <br />
                Můžete Správce požádat o opravu nepřesných osobních údajů.
              </p>
              <p>
                Právo na výmaz:
                <br />
                Můžete Správce požádat o výmaz zpracovávaných osobních údajů; a to za podmínek
                uvedených v článku 17 GDPR.
              </p>
              <p>
                Právo odvolat souhlas:
                <br />
                Můžete kdykoli odvolat již udělený souhlas se zpracováním, a zabránit tak dalšímu
                zpracování pro účel uvedený v daném souhlasu. Odvoláním souhlasu nicméně není
                dotčena zákonnost zpracování v období před jeho odvoláním.
              </p>
              <p>
                Právo vznést námitku:
                <br />
                Pokud Správce zpracovává Vaše osobní údaje na základě svých oprávněných zájmů, máte
                právo vznést námitku proti zpracování osobních údajů, které se Vás týkají.
              </p>
              <p>
                Právo na omezení zpracování:
                <br />V určitých případech můžete požadovat, aby Správce omezil prováděné zpracování
                (např. do doby vyřešení Vašich námitek).
              </p>
              <p>
                Právo na přenositelnost údajů:
                <br />
                Můžete požádat Správce, aby Vám či třetí osobě předal osobní údaje, které o Vás na
                základě smlouvy či Vašeho souhlasu zpracovává v elektronické podobě.
              </p>
            </p>
            <p className="px-2">
              <strong>6.2.</strong> Pokud se uživatel domnívá, že jeho osobní údaje jsou
              zpracovávány v rozporu s právními předpisy, má právo obrátit se na Správce s žádostí o
              zjednání nápravy. Jestliže bude žádost uživatele oprávněná, Správce neprodleně
              odstraní závadný stav. Tím není dotčena Vaše možnost podat stížnost přímo u Úřadu pro
              ochranu osobních údajů (www.uoou.cz).
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GDPR;
