import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NavIcon from './NavIcon';

import ikonaUklid from '../../../assets/img/zmente_ikony/ikona_uklid_a_zelen@3x.png';
import ikonaDoprava from '../../../assets/img/zmente_ikony/ikona_verejna_doprava_modra_ramecek@3x.png';
import ikonaSilnice from '../../../assets/img/zmente_ikony/ikona_silnice_a_chodniky@3x.png';
import ikonaOsvetleni from '../../../assets/img/zmente_ikony/ikona_osvetleni@3x.png';
import ikonaOstatni from '../../../assets/img/zmente_ikony/ikona_ostatni@3x.png';
import ikonaUrady from '../../../assets/img/zmente_ikony/ikona_hodnoceni_uradu@3x.png';

export const icons = [
  {
    image: ikonaDoprava,
    title: 'Veřejná doprava',
    tooltip: 'zastávky, dostupnost...',
    link: '/verejna-doprava',
    id: 74,
  },
  {
    image: ikonaSilnice,
    title: 'Silnice a chodníky',
    tooltip: 'cyklisté, doprava...',
    link: '/silnice-a-chodniky',
    id: 82,
  },
  {
    image: ikonaUklid,
    title: 'Úklid a zeleň',
    tooltip: 'veřejná prostranství...',
    link: '/uklid-a-zelen',
    id: 83,
  },
  {
    image: ikonaOsvetleni,
    title: 'Osvětlení',
    tooltip: 'poruchy, poškození...',
    link: '/osvetleni',
    id: 84,
  },
  {
    image: ikonaOstatni,
    title: 'Ostatní',
    tooltip: 'ilegální reklama, bezbariérovost...',
    link: '/ostatni',
    id: 85,
  },
  {
    image: ikonaUrady,
    title: 'Hodnocení úřadu',
    tooltip: 'Magistrát hl. m. Prahy',
    link: '/hodnoceni-uradu',
    id: 5,
  },
];

const NavIcons: FC = () => {
  return (
    <Container fluid="md">
      <Row className="pb-3">
        {icons.map((icon) => {
          return (
            <Col xs={6} md={4} key={icon.id}>
              <NavIcon
                image={icon.image}
                name={icon.title}
                tooltip={icon.tooltip}
                link={icon.link}
                id={icon.id}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default NavIcons;
