import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Props } from '../../../App';

const About: FC<Props> = (props): JSX.Element => {
  return (
    <section className="top">
      <Container fluid="md">
        <h3 className="mb-5 display-6 text-center">{props.header}</h3>
        <Row className="mb-5">
          <Col sm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <p className="mb-3 px-3">
              Změňte.to představuje jednotné místo, které vám nabízí možnost posílat podněty ke
              zlepšení veřejného prostoru a hlášení závad na území Prahy. Podnět zadáte jednoduše,
              pořídíte fotografii, označíte místo přímo na mapě, a s krátkým komentářem během chvíle
              odešlete. Podnětem se začne okamžitě zabývat kvalifikovaný tým zpracovatelů, který
              předmět analyzuje, předá konkrétní řešitelské instituci a v průběhu vyřízení vás
              pravidelně informuje o jeho průběhu. Součástí je také funkce Hodnocení úřadu, kdy je
              možné předat zpětnou vazbu na procesy a chod Magistrátu hlavního města Praha.
            </p>
            <a className="link-success px-3" href="/ochrana-osobnich-udaju">
              Ochrana osobních údajů
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
