/* eslint-disable array-callback-return */
//counst FileList length
export function countArrayLength(files?: FileList): number {
  let count: number = 0;
  if (files) {
    const filesArr = Array.from(files);
    count = filesArr.length;
  }
  return count as number;
}

export function toArray(files?: FileList) {
  return [...(files as unknown as FileList)];
}

// file size and format validators
export function checkIfFilesAreTooBig(files?: [File]): boolean {
  let valid = true;
  if (files) {
    const a = Array.from(files);
    a.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 5) {
        valid = false;
      }
    });
  }
  return valid;
}

export function checkIfFilesAreCorrectType(files?: [File]): boolean {
  let valid = true;
  if (files) {
    const a = Array.from(files);
    a.map((file) => {
      if (!['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
}

export function checkIfFilesAreOverLimit3(files?: [File]): boolean {
  let valid = true;
  if (files) {
    const a = Array.from(files);
    if (a.length > 3) {
      valid = false;
    }
  }
  return valid;
}
export function checkIfNoFiles(files?: [File]): boolean {
  let valid = true;
  if (files) {
    const a = Array.from(files);
    if (a.length === 0) {
      valid = false;
    }
  }
  return valid;
}
