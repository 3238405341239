import { createContext, useState, Dispatch, SetStateAction, useCallback } from 'react';
import { ISuggestionForm } from '../components/ui/SuggestionForm/SuggestionForm';
import * as SuggestionAPI from '../api/SuggestionAPI';

export interface IChildren {
  children?: React.ReactNode;
}

interface IAppCtx {
  LatLng: google.maps.LatLngLiteral | null | undefined;
  setLatLng: Dispatch<SetStateAction<google.maps.LatLngLiteral | null | undefined>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  error: string | null;
  setError: Dispatch<SetStateAction<string | null>>;
  sendSuggestion: (formObject: ISuggestionForm) => void;
  sendAll: (files: File[], formObject: ISuggestionForm) => void;
  regNum: string | undefined;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  getSurveysCount: () => void;
  surveysCount: number | undefined;
  setSurveysCount: Dispatch<SetStateAction<number | undefined>>;
}

const mapSuggestionRequest = (formObject: ISuggestionForm): SuggestionAPI.ISuggestionRequest => {
  return {
    subcategory_id: formObject.subcategory_id,
    gdpr_agreement: formObject.gdpr_agreement,
    source: 'WEB',
    values: {
      email: formObject.email,
      description: formObject.description,
      date: formObject.date,
      gps: formObject.gps,
    },
    number_of_files: 0,
    file_ids: [],
  };
};

const AppContext = createContext<IAppCtx>({
  LatLng: undefined,
  setLatLng: () => {},
  isLoading: false,
  setIsLoading: () => false,
  error: null,
  setError: () => null,
  sendSuggestion: () => {},
  sendAll: () => {},
  regNum: '',
  show: false,
  setShow: () => {},
  getSurveysCount: () => {},
  surveysCount: undefined,
  setSurveysCount: () => {},
});

export const AppContextProvider = ({ children }: IChildren) => {
  const [LatLng, setLatLng] = useState<google.maps.LatLngLiteral | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [regNum, setRegNum] = useState<string>();
  const [show, setShow] = useState(false);
  const [surveysCount, setSurveysCount] = useState<number | undefined>();

  // send form suggestion
  const sendSuggestion = async (formObject: ISuggestionForm) => {
    setIsLoading(true);
    setError(null);
    try {
      const regNum = await SuggestionAPI.sendSuggestion(mapSuggestionRequest(formObject));
      setRegNum(() => regNum);
    } catch (error: any) {
      console.log('error', error);
      setError(() => error.message);
    }
    setIsLoading(false);
  };

  // send all data
  const sendAll = async (files: File[], formObject: ISuggestionForm) => {
    setIsLoading(true);
    setError(null);
    try {
      const fileIds = await SuggestionAPI.sendFiles(files);
      const suggestionRequest = mapSuggestionRequest(formObject);
      if (fileIds.length > 0) {
        suggestionRequest.file_ids = fileIds;
        suggestionRequest.number_of_files = fileIds.length;
      }
      const regNum = await SuggestionAPI.sendSuggestion(suggestionRequest);
      setRegNum(() => regNum);
    } catch (error: any) {
      console.log('error', error);
      setError(() => error.message);
    }
    setIsLoading(false);
  };

  // get survey count
  const getSurveysCount = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const surveys = await SuggestionAPI.getSurveysCount();
      setSurveysCount(surveys);
    } catch (error: any) {
      console.log('error', error);
      setError(() => error.message);
    }
    setIsLoading(false);
  }, []);
  // provide a value
  return (
    <AppContext.Provider
      value={{
        LatLng: LatLng,
        setLatLng: setLatLng,
        isLoading,
        setIsLoading,
        error,
        setError,
        sendSuggestion: sendSuggestion,
        sendAll: sendAll,
        regNum: regNum,
        show: show,
        setShow: setShow,
        getSurveysCount: getSurveysCount,
        surveysCount: surveysCount,
        setSurveysCount: setSurveysCount,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
