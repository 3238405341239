import React, { FC } from 'react';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';

import LogoZmenteTo from '../../../assets/img/logos/zmente_to_logo_color.svg';

const NavTop: FC = () => {
  return (
    <header>
      <Navbar expand="md" fixed="top" className="mb-4 justify-content-center bg-light shadow">
        <Container fluid="md">
          <Navbar.Brand>
            <Nav.Link href="/">
              <Image src={LogoZmenteTo} width="110" className="bg-light rounded" alt="Změňte.to" />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-3" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="pe-5">
              <Nav.Item>
                <Nav.Link className="ms-3 link-dark fs-4" href="/">
                  domů
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="ms-3 link-dark fs-4" href="/o-projektu">
                  o projektu
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="ms-3 link-dark fs-4" href="/napoveda">
                  nápověda
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavTop;
