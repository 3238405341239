import { FC, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import { Props } from '../../../App';
import AppContext from '../../../store/appContext';
import { ModalComponent as Modal } from '../../ui/Modal/Modal';
import GMap from '../../ui/Map/GoogleMap';
import FileField from '../../ui/FileField';
import {
  getAdditionalInfo,
  IAdditionalInfo,
  IAdditionalInfoForm,
  sendAdditionalInfo,
} from '../../../api/AdditionalInfoAPI';
import { mixed, object, string } from 'yup';
import {
  checkIfFilesAreCorrectType,
  checkIfFilesAreOverLimit3,
  checkIfFilesAreTooBig,
  checkIfNoFiles,
} from '../../ui/SuggestionForm/helperFunctions';
import BStrapFormikTextArea from '../../ui/BStrapFormikTextArea';
import StyledErrorMessage from '../../ui/StyledErrorMessage/StyledErrorMessage';

const style = {
  border: '3px solid #2dcfa7',
};

const AdditionalInfo: FC<Props> = (props): JSX.Element => {
  const { error, LatLng, setError, setIsLoading, setShow } = useContext(AppContext);
  const { token, surveyId } = useParams();

  const navigate = useNavigate();

  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [infoData, setInfoData] = useState<IAdditionalInfo | null>(null);
  const [schema, setSchema] = useState<any>(null);

  const getInfo = useCallback<() => void>(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const info = await getAdditionalInfo(token!, surveyId!);
      setInfoData(info);
      setSchema(getYupSchemaFromResponse(info));
    } catch (error: any) {
      console.log('error', error);
      setError(() => error.message);
      navigate('/');
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, surveyId]);

  const sendInfo = async (files: File[], formObject: IAdditionalInfoForm) => {
    setIsLoading(true);
    setError(null);
    try {
      await sendAdditionalInfo(files, formObject);
    } catch (error: any) {
      console.log('error', error);
      setError(() => error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!token || !surveyId) {
      return navigate('/');
    }

    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    description: '',
    gps: '',
    other: '',
    files: null,
  };

  const fullSchema = {
    description: string()
      .min(5, 'Příliš krátké! Min 5 znaků')
      .max(500, 'Příliš dlouhé! (max 500 znaků)')
      .required('Vyplňte, prosím'),
    files: mixed()
      .required('Přiložte soubor/y')
      .test(
        'fileSize',
        'Soubor je příliš veliký, max velikost je 5 MB/soubor',
        checkIfFilesAreTooBig
      )
      .test(
        'fileType',
        'Neplatný typ souboru (povolené jsou jpg, gif a png)',
        checkIfFilesAreCorrectType
      )
      .test('fileCount', 'Maximální počet souborů je 3', checkIfFilesAreOverLimit3)
      .test('fileCount', 'Přiložte soubor/y', checkIfNoFiles),
    other: string()
      .min(5, 'Příliš krátké! Min 5 znaků')
      .max(500, 'Příliš dlouhé! (max 500 znaků)')
      .required('Vyplňte, prosím'),
  };

  const getYupSchemaFromResponse = (data: IAdditionalInfo) => {
    return object().shape({
      ...(data.info.description && { description: fullSchema.description }),
      // ...(data.info.gps && { gps: fullSchema.gps }),
      ...(data.info.photos && { files: fullSchema.files }),
      ...(data.info.parcel && { other: fullSchema.other }),
    });
  };

  const modalTitle = !error ? 'Doplnění informací' : 'Chyba';
  const modalBodyText = !error ? 'Vaše údaje byly úspěšně přidány. Děkujeme' : error;

  return (
    <section className="top">
      <Modal title={modalTitle} body={modalBodyText} />
      <Container fluid="md">
        <h3 className="mb-5 display-6 text-center">{props.header}</h3>
        <Row className="mb-5">
          <Col>
            <h3>Váš podnět: {infoData?.survey?.reg_num}</h3>
            <p>Doplňte prosím tyto údaje:</p>
            <ul>
              {!!infoData?.info?.description && (
                <li>
                  <strong>Chybí popis</strong>
                  <br />
                  Ze zaslaných informací bohužel není patrné, čeho se týká, prosíme proto o bližší
                  upřesnění popisu. Teprve poté bude možné zjistit správce dané lokality a předat
                  příslušné instituci k vyjádření.
                </li>
              )}
              {!!infoData?.info?.photos && (
                <li>
                  <strong>Chybí přiložená fotografie</strong>
                  <br />
                  Fotografie nesmí být příliš detailní, měla by zobrazovat širší okolí tak, aby bylo
                  možné identifikovat místo podnětu. Prosíme, zasílejte od nynějška podnět i s
                  fotografií.
                </li>
              )}
              {!!infoData?.info?.gps && (
                <li>
                  <strong>Poloha / GPS</strong>
                  <br />
                  Ze zaslaných informací bohužel není patrné, kde se problém nachází. Prosíme o
                  vložení přesné GPS lokace, teprve poté bude možné zjistit správce dané lokality a
                  předat příslušné instituci k vyjádření.
                </li>
              )}
              {!!infoData?.info?.parcel && (
                <li>
                  <strong>Číslo parcely</strong>
                  <br />
                  Ze zaslaných informací bohužel není patrné, kde se problém nachází. Prosíme o
                  přesné určení čísla parcely
                </li>
              )}
            </ul>

            <p>Předchozí zadané informace budeme mít pořád k dispozici, jen žádáme o upřesnění.</p>
          </Col>
        </Row>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          validateOnBlur={true}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);

            if (!!infoData?.info.gps && !LatLng) {
              return;
            }

            const data: IAdditionalInfoForm = {
              survey_id: infoData!.info.survey_id,
              id: infoData!.info.id,
              ...(values.description && { description: values.description }),
              ...(values.gps && { gps: values.gps }),
              ...(values.other && { other: values.other }),
            };

            await sendInfo(values.files || [], data);
            setShow(() => true);

            actions.setSubmitting(false);
            actions.resetForm();
          }}
        >
          {({ values, errors, touched, setFieldValue, isSubmitting }) => (
            <Form>
              {!!infoData?.info?.description && (
                <Row className="mb-5">
                  <Col>
                    <h3>Popis podnětu</h3>
                    <p>
                      <i>{infoData?.survey?.description}</i>
                    </p>
                    <div className="mb-3">
                      <BStrapFormikTextArea
                        id="description"
                        name="description"
                        label="Popis"
                        placeholder="Popis situace"
                        type="textarea"
                        style={style}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {!!infoData?.info?.photos && (
                <Row className="mb-5">
                  <Col>
                    <h3>Nahrané fotografie</h3>
                    <div className="mb-2">
                      <FileField
                        files={infoData?.survey?.files || []}
                        survey_id={surveyId || ''}
                        onClick={() => setShow(true)}
                        imageWidth={8}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="files">
                        Připojit fotografie: (max 3, do 5MB)
                      </label>
                      <input
                        ref={fileRef}
                        className="form-control mb-3"
                        style={style}
                        name="files"
                        id="files"
                        type="file"
                        accept="image/jpg, image/jpeg, image/gif, image/png"
                        multiple
                        onChange={(event) => {
                          setFieldValue('files', event.currentTarget.files);
                        }}
                      />
                      {touched.files && errors.files ? (
                        <StyledErrorMessage>{errors.files}</StyledErrorMessage>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              )}

              {!!infoData?.info?.gps && (
                <Row className="mb-5">
                  <Col>
                    <h3>GPS souřadnice</h3>
                    <GMap />
                    {!LatLng && <StyledErrorMessage>Vyplňte polohu, prosím</StyledErrorMessage>}
                  </Col>
                </Row>
              )}

              {!!infoData?.info?.parcel && (
                <Row className="mb-5">
                  <Col>
                    <h3>Ostatní</h3>
                    <div className="mb-3">
                      <BStrapFormikTextArea
                        id="other"
                        name="other"
                        label="Dodatečné informace"
                        placeholder="Dodatečné informace k podnětu"
                        type="textarea"
                        style={style}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              <div className="clearfix">
                <button
                  className="btn btn-outline-dark shadow float-end"
                  type="submit"
                  style={style}
                  disabled={isSubmitting}
                >
                  Odeslat
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </section>
  );
};

export default AdditionalInfo;
