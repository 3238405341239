import React, { FC } from 'react';
import { Image, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../ui/Icon/Icon';

interface Props {
  image: string;
  name: string;
  tooltip: string;
  link: string;
  id: number;
}

const NavIcon: FC<Props> = (props): JSX.Element => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id={`tooltip-top`}>{props.tooltip}</Tooltip>}
    >
      <Nav.Link href={props.link} className="d-flex flex-column align-items-center">
        <Icon>
          <Image fluid src={props.image} alt={props.name} />
        </Icon>
        <span className="fs-5 text-center link-dark">{props.name}</span>
      </Nav.Link>
    </OverlayTrigger>
  );
};

export default NavIcon;
