import React, { FC } from 'react';

import { Container } from 'react-bootstrap';

import NavIcons from '../../layout/NavIcons/NavIcons';

import { Props } from '../../../App';

const Home: FC<Props> = (props): JSX.Element => {
  return (
    <section className="top">
      <Container fluid="md">
        <h1 className="px-3 display-5 text-center">{props.header}</h1>
        <p className="px-3 fs-4 text-center fw-light lh-lg">
          Vyberte si oblast, kterou chcete změnit.
        </p>
        <NavIcons />
      </Container>
    </section>
  );
};

export default Home;
