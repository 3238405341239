import { Field, Form, Formik } from 'formik';
import { FC, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { object, string } from 'yup';
import axios from 'axios';

import { Props } from '../../../App';
import { configuration } from '../../../configuration';
import AppContext from '../../../store/appContext';
import { ModalComponent as Modal } from '../../ui/Modal/Modal';
import StyledErrorMessage from '../../ui/StyledErrorMessage/StyledErrorMessage';

const RemoveConsentSchema = object().shape({
  email: string().email('Neplatná e-mailová adresa').required('Vyplňte, prosím'),
});

const RemoveConsent: FC<Props> = (props): JSX.Element => {
  const { error, setError, setIsLoading, setShow } = useContext(AppContext);

  const revokeConsent = async (payload: any) => {
    setIsLoading(true);
    setError(null);
    const url = `${configuration.API_URL}/survey-management/gdpr`;
    try {
      await axios.post(url, payload, {
        headers: {
          Authorization: `${configuration.API_KEY}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (error: any) {
      console.log('error', error);
      setError(() => error.message);
    }
    setShow(() => true);
    setIsLoading(false);
  };

  const modalTitle = !error ? 'Odvolání souhlasu' : 'Chyba';
  const modalBodyText = !error ? 'Odvolali jsme Váš souhlas, děkujeme.' : error;

  return (
    <section className="top">
      <Modal title={modalTitle} body={modalBodyText} />
      <Container fluid="md">
        <h3 className="mb-5 display-6 text-center">{props.header}</h3>
        <Row className="mb-5">
          <Col sm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <p className="mb-3">
              Odvoláním souhlasu se zpracováním osobních údajů odstraníme veškeré vaše osobní údaje
              u podnětů, které jsou již vyřízené. U nevyřízených podnětů odstraníme vaše údaje
              okamžitě po odeslání e-mailu o vyřízení.
            </p>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={RemoveConsentSchema}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(true);
                await revokeConsent({ email: values.email.trim() });
                actions.resetForm();
                actions.setSubmitting(false);
              }}
              validateOnBlur={true}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="mb-3">
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Zde vypište e-mail, který chcete odstranit z našeho systému"
                      className="form-control"
                    />
                    {errors.email && touched.email ? (
                      <StyledErrorMessage>{errors.email}</StyledErrorMessage>
                    ) : null}
                  </div>
                  <div className="text-center">
                    <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                      Odstranit veškeré údaje
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RemoveConsent;
