import { FC } from "react";
import { Props } from '../../../App';
import { Col, Container, Row } from "react-bootstrap";

const Cookies: FC<Props> = (props): JSX.Element => {
  return (
    <section>
      <Container fluid="md">
        <h3 className="mb-5 display-6 text-center">{props.header}</h3>
        <Row className="mb-5">
          <Col sm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
            <p className="px-2">
              Web <a href="https://zmente.to/" target="_blank" rel="noreferrer">zmente.to</a> používá jen technická cookies, která jsou nezbytná pro fungování webu. 
              Pro webovou analytiku využíváme Plausible Analytics, který cookies ke svému fungování nepotřebuje. 
              Tento nástroj negeneruje žádné trvalé identifikátory a neshromažďuje ani neukládá žádné osobní 
              nebo identifikovatelné údaje. Všechna data jsou pouze agregovaná data a neobsahují žádné osobní údaje. 
              Pomocí Plausible Analytics se všechna měření webu provádějí absolutně anonymně.
            </p>
            <p className="px-2">
              Více informací na <a href="https://plausible.io/data-policy" target="_blank" rel="noreferrer">plausible.io/data-policy</a>.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Cookies;