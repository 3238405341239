import React, { FC, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './views/home';
import About from './views/o-projektu';
import Help from './views/napoveda';
import Suggestion from './views/suggestion';
import OfficeEval from './views/hodnoceni-uradu';
import RemoveConsent from './views/odvolani-souhlasu';
import AdditionalInfo from './views/doplneni-informaci';
import GDPR from './views/ochrana-osobnich-udaju';
import Cookies from './views/cookies';

import { Title } from './title';

import { icons } from './layout/NavIcons/NavIcons';

export const Router: FC = () => {
  const [links] = useState([
    { link: '/', title: '', component: <Home header="námět | stížnost | pochvala" /> },
    { link: '/o-projektu', title: 'O projektu', component: <About header="O projektu" /> },
    { link: '/ochrana-osobnich-udaju', title: 'Ochrana osobních údajů', component: <GDPR header="Informace o zpracování osobních údajů" />},
    { link: '/napoveda', title: 'Nápověda', component: <Help header="Nápověda" /> },
    { link: '/cookies', title: 'Cookies', component: <Cookies header="Cookies" /> },
    {
      link: '/hodnoceni-uradu',
      title: 'Hodnocení úřadu',
      component: <OfficeEval header="Hodnocení úřadu" />,
    },
    {
      link: '/odvolani-souhlasu',
      title: 'Odvolání souhlasu',
      component: <RemoveConsent header="Odvolání souhlasu" />,
    },
    {
      link: '/doplneni-informaci/:surveyId/:token',
      title: 'Doplnění informací',
      component: <AdditionalInfo header="Doplnění informací" />,
    },
  ]);
  return (
    <Routes>
      {/* physical links */}
      {links.map((link) => {
        return (
          <Route
            key={link.link}
            path={link.link}
            element={<Title title={link.title}>{link.component}</Title>}
            // strict={true}
          />
        );
      })}
      {/* generated form links */}
      {icons.map((link) => {
        return (
          <Route
            key={link.id}
            path={link.link}
            element={
              <Title title={link.title}>
                <Suggestion header={link.title} id={link.id} />
              </Title>
            }
            // strict={true}
          />
        );
      })}
    </Routes>
  );
};
