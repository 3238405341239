import { FC, useContext, useState, useRef, MutableRefObject } from 'react';
import { boolean, date, mixed, object, string } from 'yup';
import { Formik, Form } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';

import styles from './SuggestionForm.module.scss';

import { ModalComponent as Modal } from '../Modal/Modal';
import AppContext from '../../../store/appContext';

// input components
import GMap from '../Map/GoogleMap';
import BStrapFormikTextArea from '../BStrapFormikTextArea';
import BStrapFormikCheckbox from '../BStrapFormikCheckbox';
import BStrapFormikInput from '../BStrapFormikInput';
import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';

import '../../../styles/custom.scss';
import '../../../styles/DateTimePicker.css';

//form custom helper functions
import {
  checkIfFilesAreTooBig,
  checkIfFilesAreCorrectType,
  checkIfFilesAreOverLimit3,
  checkIfNoFiles,
  toArray,
} from './helperFunctions';

interface Values {
  description: string;
  email: string;
  denyContact: boolean;
  gdpr_agreement: boolean;
  addPhoto: boolean;
  files?: File | null;
  dateTime: Date;
}

export interface ISuggestionForm {
  subcategory_id: number;
  email: string;
  description: string;
  gps: string;
  date: string;
  gdpr_agreement: boolean;
}

const style = {
  border: '3px solid #2dcfa7',
};

// Yup validation schema
const SuggestionSchema = object().shape({
  description: string()
    .min(5, 'Příliš krátké! Min 5 znaků')
    .max(500, 'Příliš dlouhé! (max 500 znaků)')
    .required('Vyplňte, prosím'),
  email: string().when('denyContact', {
    is: false,
    then: string().email('Neplatná e-mailová adresa').required('Vyplňte, prosím').nullable(),
  }),
  dateTime: date().required('Vyplňte, prosím'),
  addPhoto: boolean(),
  files: mixed().when('addPhoto', {
    is: true,
    then: mixed()
      // .nullable()
      .required('Přiložte soubor/y')
      .test(
        'fileSize',
        'Soubor je příliš veliký, max velikost je 5 MB/soubor',
        checkIfFilesAreTooBig
      )
      .test(
        'fileType',
        'Neplatný typ souboru (povolené jsou jpg, gif a png)',
        checkIfFilesAreCorrectType
      )
      .test('fileCount', 'Maximální počet souborů je 3', checkIfFilesAreOverLimit3)
      .test('fileCount', 'Přiložte soubor/y', checkIfNoFiles),
  }),
  denyContact: boolean(),
  gdpr_agreement: boolean().when('denyContact', {
    is: false,
    then: boolean()
      .required('Bez přijetí podmínek nemůže být formulář odeslán.')
      .oneOf([true], 'Bez přijetí podmínek nemůže být formulář odeslán.'),
  }),
});

// the form
interface FProps {
  id: number;
}

const SuggestionForm: FC<FProps> = (props) => {
  const { error, LatLng, regNum, sendAll, setShow, sendSuggestion } = useContext(AppContext);

  const [valueDateTime, setDateTime] = useState(new Date());
  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  const gpsString = `${LatLng?.lat}, ${LatLng?.lng}`;

  const initialValues: Values = {
    description: '',
    email: '',
    gdpr_agreement: false,
    denyContact: false,
    addPhoto: false,
    files: null,
    dateTime: valueDateTime,
  };

  const modalTitle = !error ? 'Děkujeme za Váš podnět' : 'Chyba';
  const modalBodyText = !error
    ? `Vašemu podnětu s registračním číslem ${regNum} se budeme náležitě věnovat.`
    : error;

  return (
    <Container className="mb-5">
      <Modal title={modalTitle} body={modalBodyText} />
      <Formik
        initialValues={initialValues}
        validationSchema={SuggestionSchema}
        validateOnBlur={true}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          // reset email and gdpr if sender wants to stay anonymous
          if (values.denyContact) {
            values.email = 'anonym@zmente.to';
            values.gdpr_agreement = false;
          }
          // remove photos if attachments unchecked
          if (!values.addPhoto) {
            values.files = null;
            values.addPhoto = false;
          }
          values.dateTime = valueDateTime;
          if (!LatLng) {
            return;
          }
          const sugForm: ISuggestionForm = {
            subcategory_id: props.id,
            email: values.email,
            description: values.description,
            gps: gpsString,
            date: values.dateTime.toISOString(),
            gdpr_agreement: values.gdpr_agreement,
          };
          if (values.files) {
            const fileListArr = toArray(values.files as unknown as FileList);
            sendAll(fileListArr, sugForm);
            setShow(() => true);
          } else {
            sendSuggestion(sugForm);
            setShow(() => true);
          }
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ values, errors, touched, setFieldValue, isSubmitting }) => (
          <Form className="mx-3 text-dark">
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="react-select-3-input">
                    Lokace
                  </label>
                  <GMap isAutocomplete />
                  {!LatLng && <StyledErrorMessage>Vyplňte polohu, prosím</StyledErrorMessage>}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <BStrapFormikTextArea
                    id="description"
                    name="description"
                    label="Popis"
                    placeholder="Popis situace"
                    type="textarea"
                    style={style}
                  />
                </div>
                <label className="form-label d-block" htmlFor="datetime">
                  Datum a čas nálezu
                </label>
                <DateTimePicker
                  className="mb-3"
                  value={valueDateTime}
                  onChange={setDateTime}
                  maxDate={new Date(Date.now())}
                  locale={'cs-CZ'}
                  clearIcon={null}
                />
                {errors.dateTime && touched.dateTime ? (
                  <StyledErrorMessage>{errors.dateTime}</StyledErrorMessage>
                ) : null}
                <div className="mb-3">
                  <BStrapFormikCheckbox
                    name="addPhoto"
                    id="addPhoto"
                    label="připojit fotografie"
                    type="checkbox"
                    style={style}
                  />
                </div>
                {values.addPhoto && (
                  <div className="mb-3">
                    <input
                      ref={fileRef}
                      className="form-control mb-3"
                      style={style}
                      name="files"
                      id="files"
                      type="file"
                      accept="image/jpg, image/jpeg, image/gif, image/png"
                      multiple
                      onChange={(event) => {
                        setFieldValue('files', event.currentTarget.files);
                      }}
                    />
                    <button
                      className="btn btn-outline-dark"
                      type="button"
                      style={style}
                      onClick={() => (fileRef.current.value = '')}
                    >
                      Odstranit soubory
                    </button>
                    {errors.files && touched.files ? (
                      <StyledErrorMessage>{errors.files}</StyledErrorMessage>
                    ) : null}
                  </div>
                )}
                {!values.denyContact && (
                  <div>
                    <div className="mb-3">
                      <BStrapFormikInput
                        id="email"
                        name="email"
                        label="Váš e-mail"
                        placeholder="Váš e-mail"
                        type="email"
                        style={style}
                      />
                    </div>
                    <div className="mb-3">
                      <BStrapFormikCheckbox
                        name="gdpr_agreement"
                        id="gdpr_agreement"
                        label="souhlasím se zpracováním osobních údajů"
                        type="checkbox"
                        style={style}
                      />
                    </div>
                  </div>
                )}
                <div className="mb-3">
                  <BStrapFormikCheckbox
                    name="denyContact"
                    id="denyContact"
                    label="nechci vyplnit e-mail a být kontaktován"
                    type="checkbox"
                    style={style}
                  />
                </div>
                <button
                  className={`btn ${styles['send-button']}`}
                  type="submit"
                  style={style}
                  disabled={isSubmitting}
                >
                  Odeslat
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default SuggestionForm;
