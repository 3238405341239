import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Props } from '../../../App';

const Help: FC<Props> = (props): JSX.Element => {
  return (
    <section className="top">
      <Container fluid="md">
        <h3 className="mb-5 display-6 text-center">{props.header}</h3>
        <Row>
          <Col sm={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="mb-5 px-4">
            <p>
              Nenašel jsem v nabízené kategorii žádnou, které se týká můj podnět. Jak mám
              postupovat?
            </p>
            <p>V takovém případě prosím využijte kategorii Ostatní podněty.</p>
            <hr />
            <p>K čemu slouží v podnětu fotografie?</p>
            <p>
              Ve většině případech se váš podnět týká konkrétního nevyhovujícího stavu nebo
              konkrétní lokality a fotografie slouží k určení čeho se podnět týká. V některých
              případech fotografie pomůže upřesnit lokaci více než pomocí mapy.
            </p>
            <hr />
            <p>Nedokážu určit místo svého podnětu na mapě, jak mám postupovat?</p>
            <p>
              V takovém případě zadejte nejbližší adresu. V dalších krocích se pokuste co nejvíce
              upřesnit, jaké lokace se váš podnět týká. Velmi nám tím pomůžete a zrychlíte celý
              proces zpracování podnětu.
            </p>
            <hr />
            <p>Co se stane s mým podnětem po odeslání?</p>
            <p>
              Po odeslání vašeho podnětu dojde k jeho zpracování během tří pracovních dnů. Následně
              projde podnět kontrolou a v případě chybějících údajů vás vyzveme k jejich doplnění.
              Jakmile bude podnět obsahovat veškeré potřebné informace předáme podnět k řešení
              příslušné městské instituci či odboru Magistrátu hlavního města Prahy. Po obdržení
              informace od instituce ohledně řešení vašeho podnětu vás budeme zpět informovat.
            </p>
            <hr />
            <p>Na můj podnět mi nepřišla žádná odpověď ani do třiceti dnů. Proč?</p>
            <p>
              Občas se stává, že se při odesílání podnětu tazatel uklikne, nejčastěji vynechá či
              vymění písmenko v e-mailové adrese, a odešle tak chybné kontaktní údaje. Týmu{' '}
              <a href="https://zmente.to/">zmente.to</a> se následně odpověď vrací zpět jako
              nedoručitelná. Z důvodu ochrany osobních údajů zároveň v takovém případě nelze zkoušet
              jiné různé varianty. Prosím, vždy si proto zkontrolujte správnost zadávané e-mailové
              adresy.
              <br />
              <br />
              Zároveň v některých případech instituce zadanou situaci řeší ihned, ale už
              neodpovídají zpět, že například poškozenou vozovku opravily. Poznáte to podle toho, že
              se situace kolem vás skutečně změní. Pokud jste nám položili dotaz a nedostali jste
              odpověď do 30 dnů, velmi se omlouváme.
            </p>
            <hr />
            <p>Co když zadám podnět anonymně?</p>
            <p>
              V případě anonymního podání není možnost vás zpět kontaktovat a informovat vás o stavu
              řešeného podnětu. Pokud takovýto podnět neobsahuje všechny náležitosti musí být pro
              nedostatek informací uzavřen. Tomuto předejdete poskytnutím osobních údajů při zadání
              podnětu.
            </p>
            <hr />
            <p>Jak mohu odvolat svůj souhlas se zpracováním osobních údajů?</p>
            <p>
              Veškeré informace týkající se ochrany osobních údajů naleznete{' '}
              <a href="https://zmente.to/files/GDPR_informace_MHMP_aplikace_zmente_to_final.pdf">
                ZDE
              </a>
              . Berte prosím na vědomí, že vaši e-mailovou adresu potřebujeme znát z toho důvodu,
              abychom vás mohli informovat, že zaslaný podnět byl vyřízen. Svůj souhlas můžete
              kdykoliv odvolat, a to prostřednictvím tohoto{' '}
              <a href="https://zmente.to/odvolani-souhlasu">odkazu</a>.
            </p>
            <hr />
            <p>Jaké výhody má mobilní aplikace Změňte.to?</p>
            <p>
              Oproti webu se můžete v mobilní aplikaci přihlásit prostřednictvím účtu PIDlitacka.cz
              a následně mít přehled vlastních podnětů na mapě. Můžete též sledovat stav svých
              podnětů.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Help;
