import axios from 'axios';
import { configuration } from '../configuration';
import { IFiles } from "../components/ui/FileField";

interface IAdditionalInfoSurvey {
  survey_id: string;
  reg_num: string;
  email: string;
  description: string;
  gps: string;
  datetime: string;
  files: IFiles[];
}

interface IAdditionalInfoDescription {
  id: number;
  survey_id: string;
  description: number;
  photos: number;
  gps: number;
  parcel: number;
  desc_added: string;
  gps_added: string;
  parcel_added: string;
}

export interface IAdditionalInfo {
  survey: IAdditionalInfoSurvey,
  info: IAdditionalInfoDescription,
}

export interface IAdditionalInfoForm {
  id: number;
  survey_id: string;
  description?: string;
  gps?: string;
  other?: string;
}

export const getAdditionalInfo = async (token: string, surveyId: string): Promise<IAdditionalInfo> => {
  const url = `${configuration.API_URL}/additional-info?token=${token}&surveyId=${surveyId}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `${configuration.API_KEY}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

export const sendAdditionalInfo = async (files: File[], data: IAdditionalInfoForm): Promise<void> => {
  const urlFiles = `${configuration.API_URL}/additional-info`;
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  formData.append('data', JSON.stringify(data));

  try {
    await axios.post(urlFiles, formData, {
      headers: {
        Authorization: configuration.API_KEY as string,
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (err) {
    throw err;
  }
}
