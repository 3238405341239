import React, { FC } from 'react';
import { Nav } from 'react-bootstrap';

import { icons } from '../NavIcons/NavIcons';

const NavBottom: FC = () => {
  return (
    <Nav className="justify-content-center">
      {icons.map((link) => {
        return (
          <Nav.Item key={link.id}>
            <Nav.Link href={link.link} className="link-dark">
              {link.title}
            </Nav.Link>
          </Nav.Item>
        );
      })}
    </Nav>
  );
};

export default NavBottom;
