import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import SuggestionForm from '../../ui/SuggestionForm/SuggestionForm';

import { Props } from '../../../App';

interface SProps extends Props {
  id: number;
}

const Suggestion: FC<SProps> = (props): JSX.Element => {
  return (
    <section className="top">
      <Container fluid="md">
        <h1 className="px-3 mb-5 display-3 text-center">{props.header}</h1>
        <SuggestionForm id={props.id} />
      </Container>
    </section>
  );
};

export default Suggestion;
