import React, { FC } from 'react';
import { Router } from './components/router';
import { BrowserRouter } from 'react-router-dom';

import NavTop from './components/layout/NavTop/NavTop';
import Footer from './components/layout/Footer/Footer';

// if you need to customize bootstrap
import './styles/custom.scss';

export interface Props {
  header: string;
}

const App: FC = () => {
  return (
    <BrowserRouter>
      <NavTop />
      <main>
        <Router />
      </main>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
