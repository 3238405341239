import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import OfficeForm from '../../ui/OfficeForm/OfficeForm';

import { Props } from '../../../App';

const OfficeEval: FC<Props> = (props): JSX.Element => {
  return (
    <section className="top">
      <Container fluid="sm">
        <h1 className="px-3 mb-5 display-3 text-center">{props.header}</h1>
        <h3 className="px-3 mb-5 display-5 text-center">Magistrát hl. města Praha</h3>
        <OfficeForm />
      </Container>
    </section>
  );
};

export default OfficeEval;
