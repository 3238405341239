import React, { FC } from 'react';
import AppContext from '../../../store/appContext';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import StoreIcons from '../../ui/StoreIcons/StoreIcons';
import NavBottom from '../NavBottom/NavBottom';
import LogoPraha from '../../../assets/img/logos/Praha_logo_bar.png';
import LogoOperatorICT from '../../../assets/img/logos/logo_oict_bila.png';

const androidUrl = 'https://play.google.com/store/apps/details?id=cz.mforce.zmenteto';
const iOSUrl = 'https://apps.apple.com/cz/app/zmente-to/id1210177921?l=cs';

const Footer: FC = () => {
  const ctx = React.useContext(AppContext);

  React.useEffect(() => {
    ctx.getSurveysCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <StoreIcons androidUrl={androidUrl} iOSUrl={iOSUrl} />
      <footer className="py-3 bg-primary-light">
        <Container>
          <Row>
            <NavBottom />
          </Row>
          <hr />
          <Row className="mb-4">
            <Col className="text-center">
              <span>Od 1. 1. 2022 už jste nám poslali {ctx.surveysCount} vzkazů.</span>
              <span className='px-2'>|</span>
              <a href="/cookies" className="link-dark text-decoration-none">Cookies</a>
            </Col>            
          </Row>
          <Row className="mb-5">
            <Col
              xs={{ span: 8, offset: 2 }}
              sm={{ span: 2, offset: 4 }}
              className="d-flex align-items-center justify-content-center justify-content-md-end mb-3"
            >
              <a href="https://praha.eu" target="_blank" rel="noreferrer">
                <Image fluid src={LogoPraha} alt="Magistrát hl. m. Prahy" />
              </a>
            </Col>
            <Col
              xs={{ span: 10, offset: 1 }}
              sm={{ span: 2, offset: 0 }}
              className="d-flex align-items-center justify-content-center mb-3"
            >
              <a href="https://operatorict.cz/" target="_blank" rel="noreferrer">
                <Image
                  fluid
                  style={{ maxHeight: '330px' }}
                  src={LogoOperatorICT}
                  alt="Operátor ICT"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
