import { configuration } from '../configuration';
import axios from 'axios';

export interface ISuggestionRequest {
  subcategory_id: number;
  number_of_files: number;
  values: {
    email: string;
    description: string;
    gps: string;
    date: string;
  };
  file_ids: number[];
  gdpr_agreement: boolean;
  source: "WEB";
}

interface ISurveysCountResponse {
  surveys: number;
  chat_surveys: number;
  chat_answered_surveys: number;
}

export const sendSuggestion = async (jsonData: ISuggestionRequest): Promise<string> => {
  const url = `${configuration.API_URL}/survey-management/clients/me/surveys`;
  try {
    const response = await axios.post(url, jsonData, {
      headers: {
        Authorization: `${configuration.API_KEY}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data.reg_num as string;
  } catch (error: any) {
    throw error;
  }
}

export const sendFiles = async (files: File[]): Promise<number[]> => {
  if (files.length === 0) {
    return [];
  }

  const urlFiles = `${configuration.API_URL}/survey-management/files`;
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  try {
    const response = await axios.post(urlFiles, formData, {
      headers: {
        Authorization: configuration.API_KEY as string,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.map((file: { id: number }) => file.id);
  } catch (err) {
    throw err;
  }
}

export const getSurveysCount = async (): Promise<number> => {
  const url = `${configuration.API_URL}/survey-management/surveys/count`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `${configuration.API_KEY}`,
      },
    });
    return (response.data as ISurveysCountResponse).surveys;
  } catch (error: any) {
    throw error;
  }
}
