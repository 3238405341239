import { FC, CSSProperties } from 'react';
import { Field, useField } from 'formik';

import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';

interface InputProps {
  label?: string;
  id: string;
  name: string;
  placeholder?: string;
  type: string;
  style?: CSSProperties;
}

const BStrapFormikCheckbox: FC<InputProps> = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <div className="form-check">
      <Field className="form-check-input" {...field} {...props} />
      <label className="form-check-label" htmlFor={props.id}>
        {label}
      </label>
      {meta.touched && meta.error ? <StyledErrorMessage>{meta.error}</StyledErrorMessage> : null}
    </div>
  );
};

export default BStrapFormikCheckbox;
