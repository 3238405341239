import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import androidImg from '../../../assets/img/StoreIcons/Android/available_on_google_play_generated.jpg';
import iosImg from '../../../assets/img/StoreIcons/RottenApple/CZ/Download_on_App_Store/White_lockup/SVG/Download_on_the_App_Store_Badge_CZ_RGB_wht_092917.svg';

interface Props {
  androidUrl: string;
  iOSUrl: string;
}

const StoreIcons: FC<Props> = (props) => {
  return (
    <Container>
      <Row className="my-3">
        <Col className="d-flex align-items-center justify-content-center justify-content-sm-end mb-3">
          <a href={props.androidUrl} target="_blank" rel="noreferrer">
            <img src={androidImg} style={{ width: '150px' }} alt="Google Play" />
          </a>
        </Col>

        <Col className="d-flex align-items-center justify-content-center  justify-content-sm-start mb-3">
          <a href={props.iOSUrl} target="blank" rel="noreferrer">
            <img src={iosImg} style={{ width: '138px' }} alt="App Store" />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default StoreIcons;
