import { FC, Fragment, CSSProperties } from 'react';
import { Field, useField } from 'formik';

import StyledErrorMessage from '../StyledErrorMessage/StyledErrorMessage';

interface InputProps {
  label?: string;
  id: string;
  name: string;
  placeholder?: string;
  type: string;
  style?: CSSProperties;
}

const BStrapFormikInput: FC<InputProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Fragment>
      <label htmlFor={props.id || props.name} className="form-label">
        {label}
      </label>
      <Field className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? <StyledErrorMessage>{meta.error}</StyledErrorMessage> : null}
    </Fragment>
  );
};

export default BStrapFormikInput;
